
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  text-decoration: none;
  color: inherit;

}

@layer base {
  h1 {
    @apply md:text-6xl sm:text-5xl font-bold text-4xl md:mb-6 md:mt-3;
    
  }
  h2 {
    @apply md:text-4xl text-2xl font-bold mb-4 mt-2;
  }
  h3 {
    @apply text-2xl font-extrabold;
  }
  h4 {
    @apply text-xl font-extrabold;
  }
  h5 {
    @apply text-lg font-extrabold;
  }
  h6 {
    @apply text-sm font-extrabold;
  }
  p {
    @apply text-sm font-normal;
  }
  ul {
    @apply list-disc list-inside;
  }
  li {
    @apply ml-5 font-medium mt-3;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

